import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full px-5 my-3" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_XIcon = _resolveComponent("XIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "text-gray-800 text-lg font-bold",
      textContent: _toDisplayString(_ctx.title)
    }, null, 8, _hoisted_2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.packageList, (pl, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'package-list-' + idx,
        class: "w-full flex flex-row items-center justify-between my-1"
      }, [
        _createElementVNode("span", {
          class: "text-gray-600 text-base",
          textContent: _toDisplayString(pl.title)
        }, null, 8, _hoisted_3),
        (pl.isActive)
          ? (_openBlock(), _createBlock(_component_CheckIcon, {
              key: 0,
              class: "w-6 h-6 ml-auto font-bold text-green-500"
            }))
          : (_openBlock(), _createBlock(_component_XIcon, {
              key: 1,
              class: "w-6 h-6 ml-auto font-bold text-red-500"
            }))
      ]))
    }), 128))
  ]))
}