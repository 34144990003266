
import { PropType, defineComponent } from "vue";

interface PackageList {
  title: string;
  isActive: boolean;
}

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "",
    },
    packageList: {
      type: Array as PropType<PackageList[]>,
      default: [],
    },
  },
  setup() {},
});
