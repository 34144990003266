
import { ResponseGetPackage } from "@/services/v1/public/types";
import {
  isPackageBasic,
  isPackagePlatinum,
  isPackagePremium,
} from "@/utils/current-user-package";
import { gpf } from "@/utils/global-functions";
import { computed, defineComponent, PropType } from "vue";
import PackageList from "./package-list.vue";

export default defineComponent({
  components: {
    PackageList,
  },
  props: {
    packageData: {
      type: Object as PropType<ResponseGetPackage>,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const prettierPackageData = computed(() => {
      const packageData = props.packageData;
      const getPercentPrice = (
        (Number(packageData?.price) / Number(packageData?.strikePrice)) *
        100
      ).toFixed(0);
      return {
        id: packageData?.id,
        title: packageData?.title,
        strikePrice: gpf.ctc(`${packageData?.strikePrice}`),
        price: gpf.ctc(`${packageData?.price}`),
        isPlatinum: packageData?.title.toLowerCase().includes("platinum"),
        dicountPercent: getPercentPrice,
      };
    });

    const eLearningPackages = computed(
      () => props.packageData?.fitur?.eLearning
    );

    const eTryoutPackages = computed(() => props.packageData?.fitur?.eLearning);

    const eMeasurePackages = computed(
      () => props.packageData?.fitur?.eLearning
    );

    const disabledBtnBuyPackage = computed(() => {
      const packageData = props.packageData;
      const isBasic = packageData?.title.toLowerCase().includes("basic");
      const isPremium = packageData?.title.toLowerCase().includes("premium");
      // const isPlatinum = packageData?.title.toLowerCase().includes("platinum");

      if (isPackageBasic.value && isBasic) return true;
      if (isPackagePremium.value && (isPremium || isBasic)) return true;
      if (isPackagePlatinum.value) return true;
      return false;
    });

    return {
      prettierPackageData,
      disabledBtnBuyPackage,
      eLearningPackages,
      eTryoutPackages,
      eMeasurePackages,
      emit,
    };
  },
});
